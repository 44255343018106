import React from 'react';

const Experience = () => {
    return (
        <div id='experience' className='experience'>
            <div className='d-flex justify-content-center my-5'>
                <h1>Experience</h1>
            </div>
             <div className='container experience-wrapper'>
                <div className='timeline-block timeline-block-left' id="experience">
                    <div className='marker'></div>
                    <div className='timeline-content'>
                        <h3>United Health Group</h3>
                        <h4>Software Engineer</h4>
                        <h3>May 2022 - Present</h3>
                        <p>
                        I've been a part of the Technology Development Program which is a year long program where you work for two different teams for 6 months each. I had the benefit of staying on the same team for both rotations as a Full Stack Software Engineer, giving me a little extra time to further develop my base of knowledge.<br/>
                        <br/>
                        • Work directly with senior software engineers on tickets ranging from backend tasks in Groovy, frontend tasks in
                        Angular, and Flyway Scripts for the database tasks.<br/>
                        • Support the MBM app and Drug Policy app by fixing defects and implementing new capabilities/ features that
                        get passed down by the business.<br/>
                        • Accountable for product lifecycle including research, proof of concepts, architect, design, develop, test, deploy,
                        and maintenance.<br/>
                        • Design and develop modular, flexible, scalable, secure, reliable and quality solutions that deliver business value
                        through superior user experience.
                        <br/>
                        {/* • Designed the relational databases used to store all of the organization’s data in the most efficient manner.<br/>
                        • Created queries using Knex to access information from the PostgreSQL databases.<br/>
                        • Implemented API endpoints using Node.js and Express to give the front end developers the data needed. */}
                        </p>
                    </div>
                </div>
                {/* - */}
                <div className='timeline-block timeline-block-right'>
                    <div className='marker'></div>
                    <div className='timeline-content'>
                        <h3>Kaebox</h3>
                        <h4>Full Stack Developer Intern</h4>
                        <h3>February 2022 - April 2022</h3>
                        <p>
                        
                        {/* <br/> */}
                        • Work closely with the lead architect to develop the company's IOS applications/ pilot products in Swift.<br/>
                        • Communicate with the QA team to make sure applications and features are all working in line with user stories.<br/>
                        • Develop a dynamic company website that will replace the initial static website.</p>
                    </div>
                </div>
                {/* - */}
                <div className='timeline-block timeline-block-left' id="experience">
                    <div className='marker'></div>
                    <div className='timeline-content'>
                        <h3>Underdog Devs</h3>
                        <h4>Back End Engineer</h4>
                        <h3>December 2021</h3>
                        <p>
                        As a part of the last month of the BloomTech curriculum, I worked on the back end of an application for Underdog Devs, a non-profit organization. Our team was the first that started building the application so we essentially built the foundation.<br/>
                        <br/>
                        • Designed the relational databases used to store all of the organization’s data in the most efficient manner.<br/>
                        • Created queries using Knex to access information from the PostgreSQL databases.<br/>
                        • Implemented API endpoints using Node.js and Express to give the front end developers the data needed.</p>
                    </div>
                </div>
                {/* - */}
                <div className='timeline-block timeline-block-right'>
                    <div className='marker'></div>
                    <div className='timeline-content'>
                        <h3>Bloom Institute of Technology</h3>
                        <h4>Student</h4>
                        <h3>April 2021</h3>
                        <p>Bloom Institute of Technology is an accelerated Full Stack Web Development program with an immersive (full-time, 40+ hours/week) hands-on curriculum with a focus on computer science, software engineering, and web development.<br/>
                        <br/>
                        OVERVIEW:<br/>
                        {/* <br/> */}
                        • Comprehensive implementation of modern JavaScript environments and tooling. (ES6+)<br/>
                        • Detailed study and implementation of data structures and algorithms.<br/>
                        • Implemented responsive web pages with semantic HTML & CSS<br/>
                        • Developed Single Page Applications with ES6+, React, Redux, & React Router.<br/>
                        • Built various backend REST APIs backed by relational databases.<br/>
                        • Implemented authentication workflows using stateless JWTs, cookie-based systems, and OAuth providers.<br/>
                        • Tested frontend/backend JavaScript applications with tooling like React-Testing-Library.<br/>
                        • Deployed web applications and microservices with Heroku & Vercel.</p>
                    </div>
                </div>
                {/* - */}
                {/* <div className='timeline-block timeline-block-left'>
                    <div className='marker'></div>
                    <div className='timeline-content'>
                        <h3>2021</h3>
                        <p>The second Bloomtech Institute of Technology's labs project I worked on was the Underdog Devs hub application. The goal of this project was to create a hub for the Underdog Devs volunteer mentor/mentee program. The hub would play a large role in matching mentees to mentors and facilitating their resources.</p>
                    </div>
                </div> */}
                {/* - */}
                {/* <div className='timeline-block timeline-block-right'>
                    <div className='marker'></div>
                    <div className='timeline-content'>
                        <h3>2021 - Present</h3>
                        <p>I graduated from Bloomtech Institute of Technology's full-stack web-development program. Currently, I am working through a Udemy course whilst searching for my next job.</p>
                    </div>
                </div> */}
                {/* - */}
            </div>
        </div>
    )
}

export default Experience;