import React from 'react';
import HeadShot from '../IMG_5817 (1).jpeg'

const AboutMe = () => {
    return (
        <div className="container py-5" id="about-me">
            <div className="row">
                <div className="col-lg-6 col-xm-12">
                    {/* <div className="photo-wrap mb-5"> */}
                        <img className="about-me-content head-shot-img" src={HeadShot} alt="Head Shot of Christopher Henao"></img>
                    {/* </div> */}
                </div>
                <div className="col-lg-6 col-xm-12">
                    {/* <div className="about-me-content mt-3 mb-5"> */}
                        <p>Hello! My name is Christopher and I enjoy creating things that I believe will make a difference in this world. My interest in software development started back in 2018 when I began researching and better understanding the technology behind Bitcoin. After realizing that a lot of the core foundations and benefits of Bitcoin are aligned with my principles, I decided that I wanted to contribute to the Bitcoin project and help further the movement. That urge eventually led me to attending a coding bootcamp that has since launched me into this tech field.</p>
                    {/* </div> */}
                </div>
            </div>
        </div>
    )
}

export default AboutMe;