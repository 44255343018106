import React from 'react';
import Typed from 'react-typed';


const Header = () => {


    return(
        <div className="header-wrapper" id="header">
            <div className="main-info">
                <h1>Full Stack Software Development</h1>
                <Typed
                className="typed-text"
                strings={["Front End", "Back End", "APIs", "JavaScript", "React", "Java", "Angular", "Node.js", "Redux", "Python", "SQL", "Databases"]}
                typeSpeed={60}
                backSpeed={60}
                loop
                />
                {/* <a href="#" className="btn-main-offer">Contact Me</a> */}
            </div>
        </div>
    )
}

export default Header;