import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Particles from 'react-tsparticles';
import NavBar from './components/NavBar';
import Header from './components/Header';
import AboutMe from './components/AboutMe';
import AboutMe2 from './components/AboutMe2';
import Experience from './components/Experience';
import Footer from './components/Footer';
import Contacts from './components/Contacts';


function App() {
  return (
    <div>
      <Particles
        params={{
          particles: {
            color: {
              value: "#0ff"
            },
            links: {
              blink: false,
              color: "#fff",
              consent: false,
              distance: 40,
              enable: true,
              opacity: 0.3,
              width: 1.5
            },
            move: {
              attract: {
                enable: false,
                rotate: {
                  x: 600,
                  y: 1200
                }
              },
              bounce: false,
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: false,
              speed: 1,
              straight: false
            },
            number: {
              density: {
                enable: false,
                area: 2000
              },
              limit: 0,
              value: 300
            },
            opacity: {
              animation: {
                enable: false,
                minimumValue: 0.05,
                speed: 2,
                sync: false
              },
              random: false,
              value: 0.5
            },
            shape: {
              type: "circle"
            },
            size: {
              animation: {
                enable: false,
                minimumValue: 0.1,
                speed: 40,
                sync: false
              },
              random: false,
              value: 0.5
            }
          }
          
        }}
      />
      <NavBar/>
      <Header/>
      <AboutMe/>
      {/* <AboutMe2/> */}
      <Experience/>
      {/* <Contacts/> */}
    </div>
    
  );
}

export default App;
